import {useRef} from 'react';
import { IonContent } from '@ionic/react';
import { CloseIcon, ArrowLeft  } from '../../assets';
import './privacyPolicy.css'

 
const PrivacyTerms: React.FC = () => {

    const textScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(textScroll.current !== null){
        textScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }

    return ( 
            <div className="privacy-container">
                <div className="privacy-policy">
                    <div className="scroll-buttons">
                        <div className="top-arrow" onClick={()=>scrollBy(-600)}>&laquo;</div>
                        <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&laquo;</div>
                    </div>
                    <CloseIcon onClick={() => window.history.back()} />
                    <IonContent className="privacy-policy-text" ref={textScroll}>
                        <h1>Condiciones de uso de Mentapp</h1>
                        <br></br>
                        <p>
                        Antes de registrarte como usuario de Mentapp y crearte una cuenta para contratar y/o utilizar nuestros servicios, debes leer y aceptar tanto nuestra Políticas de privacidad y Cookies como estas condiciones de uso.
                        </p>
                        <h3>1. Aviso legal</h3>
                        <p>
                        Te damos la bienvenida a Mentapp. <b>Fullcircle S.L.</b> con CIF B87231635 y domicilio en la Calle Fuencarral 125 2D 28010 de Madrid es la compañía tecnológica que desarrolla la plataforma disponible a través del sitio web <a href="https://mentapp.es">mentapp.es</a> y que desarrolla las herramientas digitales para terapeutas Mentapp (en adelante, las <b>“Herramientas”</b>).
                        </p>
                        <h3>2. Alta como usuario Terapeuta</h3>
                        <p>El acceso a las Herramientas implica tu registro como usuario en Mentapp y para poder identificarte deberás facilitarnos una serie de datos identificativos y de contacto básicos sobre ti como usuario.
                        <br></br>
                        <br></br>
                        Para tramitar tu alta y/o disfrutar de determinados servicios, Mentapp podrá solicitarte información adicional como tu localización, así como la verificación de tu dirección de correo electrónico o tu teléfono móvil en cualquier momento. A tal efecto, es muy importante que tengas en cuenta que deberás facilitarnos siempre tu dirección de correo electrónico o número de teléfono actual, garantizando que es tuyo y que sólo tú tienes derecho a utilizarlo.
                        <br></br>
                        <br></br>
                        Con carácter general, el acceso y uso de Mentapp como Terapeuta está prohibido a los usuarios menores de 18 años que no cuenten con titulación suficiente para ejercer la actividad,. Por tanto, al aceptar estas Condiciones, reconoces ser una persona con capacidad suficiente para adquirir las obligaciones derivadas de tus actuaciones y que has leído, comprendes y aceptas su contenido.   
                        </p>
                        <h3>3. Tus obligaciones</h3>
                        <p>
                        El acceso y/o contratación de las Herramientas de Mentapp implica tu compromiso de hacer siempre un uso correcto de los mismos, de acuerdo con estas Condiciones y, en su caso, aquellas específicas aplicables a cada servicio y, en general, la legalidad vigente y los principios de buena fe, la moral y el orden público.
                        <br></br>
                        <br></br>
                        En este sentido, queda terminantemente prohibido cualquier acceso y/o uso de Mentapp contrario a lo previsto en el párrafo anterior con o sin finalidad económica, entre otros, aquellos que puedan resultar ilegales, abusivos, fraudulentos o, en general, que puedan perjudicar los derechos de Mentapp y/o de terceras personas, entre los que se incluyen aquellos comportamientos que:
                        </p>
                        <ul>
                            <li>Fomenten o contengan materiales sexualmente explícitos (pornografía).</li>
                            <li>Fomenten la violencia o contengan materiales violentos.</li>
                            <li>Fomenten o contengan materiales difamatorios o calumniosos.</li>
                            <li>Fomenten la discriminación (sexo, raza, religión, nacionalidad, orientación sexual).</li>
                            <li>Fomenten o lleven a cabo actividades ilegales.</li>
                            <li>Violen los derechos de propiedad intelectual.</li>
                        </ul>
                        <p>
                        Tú eres el responsable del uso que decidas hacer de Mentapp, incluyendo los contenidos que decidas compartir o enviar en cada momento a través de las Herramientas ofrecidas en Mentapp. Si bien Mentapp hará todo lo razonablemente posible para vigilar la legalidad de los contenidos que se encuentren en la plataforma, Mentapp actúa como mero intermediario que pone a disposición de sus usuarios su plataforma tecnológica, asumiendo única y exclusivamente la responsabilidad derivada de la diligencia que le pudiera ser exigible por ley.
                        <br></br>
                        <br></br>
                        Si Mentapp por sus propios medios o a instancias de un tercero, detectase un uso del servicio que pudiera comprometer la seguridad, la estabilidad o el normal funcionamiento del servicio, tendrá la potestad de interrumpir el servicio. Las suspensiones del servicio derivadas de estas circunstancias, así como las consecuencias de cualquier tipo derivadas de ello, no generarán en tu favor ninguna compensación, indemnización o rebaja del precio del servicio.
                        <br></br>
                        <br></br>
                        El incumplimiento de tus obligaciones como usuario establecidas en las presentes condiciones podrá implicar la suspensión y/o baja inmediata de las Herramientas e incluso tu baja como usuario registrado de Mentapp; todo ello sin derecho a recibir compensación y/o reembolso de ningún tipo.
                        </p>
                        <h3>4. El papel de Mentapp</h3>
                        <p>
                        Mentapp permite a terceras empresas y profesionales (en adelante, “Partners” o “Terapeutas”) acceder a las Herramientas en un modelo Software as a Service (SaaS). La página de detalles de estas Herramientas contendrá la información correspondiente. A pesar de que Mentapp facilita el acceso a las Herramientas y/o las transacciones, Mentapp no actúa en ningún momento como Terapeuta ni presta directamente servicios de terapia de ningún tipo. Mentapp simplemente facilita un espacio donde los Terapetuas pueden acceder a las Herramientas y utilizarlas durante las terapias con sus pacientes. Por consiguiente, las correspondientes condiciones particulares y responsabilidades que pudieran derivarse del uso de las mismas conciernen única y exclusivamente al Terapeuta y en su caso a sus pacientes. El Terapeuta es responsable de la correcta prestación de sus servicios, así como de ofrecer asistencia en cuanto a las reclamaciones del Paciente, o con respecto a cualquier asunto relacionado con el mismo. 
                        </p>
                        <h3>5. Servicios de pago: contratación, tarifas y forma de pago</h3>
                        <p>
                        Mentapp podrá condicionar el acceso y/o uso a determinados servicios en Mentapp al pago de un precio o de una tarifa determinada, así como al cumplimiento de otros requisitos legales, comerciales o técnicos tales como la cumplimentación previa de formularios de solicitud de los datos del usuario que sean necesarios. En estos casos, la contratación de las Herramientas de pago disponibles en Mentapp se llevará a cabo, a través de los medios electrónicos habilitados en nuestra página web y/o aplicación móvil, directamente entre el usuario y Mentapp, rigiéndose la misma por estas Condiciones, así como por lo dispuesto en cualesquiera otras adicionales que se pudiesen establecer al efecto por Mentapp.
                        <br></br>
                        <br></br>
                        Por ello, antes de proceder a la contratación de nuestros productos y/o servicios de pago, recuerda comprobar siempre, en la información disponible en nuestra web y/o en la aplicación móvil, sus condiciones específicas, tarifas, impuestos aplicables, formas de pago, terminales compatibles, así como las funcionalidades asociadas a los mismos para valorar si se adecúan a tus expectativas. Además, si durante el proceso de contratación detectaras que los datos facilitados y/o forma de pago son incorrectos, erróneos, o deseas modificarlos, Mentapp podrá darte la opción de corregir los mismos hasta el momento en que se confirme la contratación.
                        <br></br>
                        <br></br>
                        A estos efectos, serán de aplicación los precios, bonos y/o, en su caso, otras condiciones económicas, contratadas de acuerdo con las tarifas y demás condiciones generales y/o particulares, ofertas o promociones en vigor en el momento de su utilización. En este sentido, Mentapp se reserva el derecho a modificar los precios de sus productos y/o servicios en cualquier momento. Si continúas utilizando las Herramientas una vez comunicado el cambio de precio, entendemos que muestras tu aceptación.
                        <br></br>
                        <br></br>
                        En lo que respecta a la forma de pago y salvo que se indicase otra cosa, ésta será mediante la tarjeta bancaria del usuario, a través de una pasarela de pagos segura o de la plataforma de pagos ofrecida en Mentapp. Una vez verificada la realización efectiva del pago correspondiente, Mentapp pondrá a disposición del usuario el justificante de la contratación donde se incluirá el detalle de las Herramientas contratados y las condiciones de los mismos.
                        </p>
                        <h3>6. Notificaciones y promociones</h3>
                        <p>
                        Mentapp podrá enviarte notificaciones para informarte sobre promociones, servicios, funcionalidades y otras novedades disponibles en Mentapp en cada momento, así como en relación con aquellos otros servicios de pago de Mentapp que pudieras haber contratado.
                        <br></br>
                        <br></br>
                        En línea con lo anterior, Mentapp podrá ofrecerte promociones, concursos y/o beneficios exclusivos sobre las Herramientas y funcionalidades disponibles en Mentapp para que, si lo deseas, los disfrutes en condiciones más ventajosas. En todo caso, la participación en este tipo de promociones implicará la aceptación, además de lo previsto en las presentes Condiciones, de las condiciones o bases legales específicas que sean de aplicación a las mismas.
                        <br></br>
                        <br></br>
                        Igualmente, conoces y aceptas que en Mentapp puede haber contenidos publicitarios y que, por tanto, como usuario podrás acceder a publicidad de productos y servicios de Mentapp y/o de terceras empresas con los que tengamos acuerdos. Del mismo modo consientes en que dicha publicidad y/o comunicaciones comerciales podrán ser enviadas al correo electrónico o número de teléfono que nos has facilitado en tu registro como usuario de Mentapp.
                        <br></br>
                        <br></br>
                        Recuerda que Mentapp quiere hacer tu experiencia lo más productiva posible, por lo que intentaremos que la publicidad y las promociones que te hacemos llegar se adapten a tu perfil. Nuestros sistemas automatizados analizan el contenido de tus comunicaciones para ofrecerte funciones de productos que sean relevantes para ti, como resultados de búsqueda y anuncios personalizados, y detección de software malicioso y spam. Este análisis se realiza cuando el contenido se envía, se recibe y cuando se almacena.
                        <br></br>
                        <br></br>
                        En nuestra Política de privacidad y cookies te explicamos con más detalle qué cookies instala Mentapp para obtener y retener información tuya con lo que hacer tu experiencia más dinámica e interesante.
                        </p>
                        <h3>7. Seguridad y privacidad</h3>
                        <p>
                        Recuerda que tú eres el único responsable de custodiar tus claves de acceso a Mentapp, así como de los posibles daños y/o perjuicios que se pudiesen ocasionar debido a un uso no autorizado de las mismas.
                        <br></br>
                        <br></br>
                        Te recomendamos que no utilices una contraseña que contenga los mismos caracteres que tu nombre de usuario, ya que disminuye la protección sobre tu cuenta.
                        <br></br>
                        <br></br>
                        En todo caso, queda prohibido el suministro de datos falsos, por tanto, te deberás identificar siempre con tus datos reales y veraces, así como facilitar un correo electrónico y/o número de teléfono móvil de tu propiedad y se te podrá requerir su verificación. Mentapp podrá desactivar temporalmente o cancelar cualquier cuenta de usuario automáticamente sin previo aviso y sin que ello te dé derecho a compensación o indemnización alguna. A modo de ejemplo, en el momento en que tengamos indicios de que has podido suplantar la identidad de un tercero en Mentapp, bien en tu alta de usuario, en la contratación de nuestros servicios y/o a la hora de verificar tu dirección de correo electrónico o número de teléfono móvil, procederemos a verificar tu identidad y/o tu número de teléfono móvil y, en su caso, a desactivar temporalmente y/o cancelar tu cuenta de Mentapp.
                        <br></br>
                        <br></br>
                        Igualmente, Mentapp podrá poner en conocimiento de las autoridades competentes o bien colaborar con éstas, cualquier posible infracción de la legislación vigente que se detecte en Mentapp, especialmente en caso de que tal infracción pudiera suponer la comisión de delito.
                        <br></br>
                        <br></br>
                        En nuestra Política de privacidad y Cookies te explicamos más detalladamente el tratamiento de datos y la protección de privacidad que realizamos en Mentapp.
                        </p>
                        <h3>8. Propiedad intelectual e industrial </h3>
                        <p>
                        Mentapp es el propietario y titular de todos los derechos sobre Mentapp (incluyendo tanto sus marcas, patentes, la plataforma web como sus aplicaciones para dispositivos móviles), a excepción de los contenidos facilitados a Mentapp por terceros colaboradores.
                        <br></br>
                        <br></br>
                        En este sentido, se te otorga únicamente una licencia limitada, temporal, no exclusiva y revocable para que pueda utilizar, descargar y/o instalar Mentapp en tus dispositivos, conforme a lo previsto en estas Condiciones y para los usos previstos. En todo caso, Mentapp se reserva los derechos no expresamente otorgados al usuario en virtud de las presentes Condiciones.
                        </p>
                        <h3>9. Modificaciones </h3>
                        <p>
                        Mentapp se reserva el derecho de revisar las presentes Condiciones en cualquier momento por razones legales, por motivos técnicos, por cambios en las Herramientas ofrecidos en Mentapp o por decisiones estratégicas o comerciales. En ese caso, te avisaremos de ello publicándolo en Mentapp y, si continúas utilizándolo y no solicitas tu baja como usuario, entenderemos que aceptas las modificaciones.
                        </p>
                        <h3>10. Ley y jurisdicción aplicable </h3>
                        <p>
                        La normativa vigente determinará las leyes que deban regir y la jurisdicción que deba conocer las relaciones entre Mentapp y los usuarios. No obstante, en aquellos casos en los que la normativa vigente no prevea la obligación de someterse a un fuero o legislación determinado, Mentapp y sus usuarios, con renuncia a cualquier otro fuero que pudiera corresponderles, se someten a los juzgados y tribunales de Madrid capital (España).
                        </p>
                        <h3>11. Responsabilidad </h3>
                        <p>
                        Mentapp no asume la obligación de controlar los contenidos que transmitan o compartan los usuarios a través de Mentapp pero sí tratará de evitar y comunicar a las autoridades pertinentes cuando tenga conocimiento de ello, en la medida de lo posible, que se transmitan y difundan contenidos y/o mensajes que puedan ser claramente ilícitos o que, de cualquier modo vayan contra la moral, el orden público o las buenas costumbres.
                        <br></br>
                        <br></br>
                        Mentapp no será responsable de los problemas de acceso o disponibilidad de Mentapp, ni de los perjuicios que se pudieran causar por ello, cuando éstos procedan de factores ajenos a su ámbito de control y actividad como son, por ejemplo, la velocidad de tu conexión a internet, el tipo de terminal o dispositivo utilizado, tu ubicación geográfica, la falta de suministro elécrico, etc.
                        <br></br>
                        <br></br>
                        Igualmente, te informamos de que Mentapp no se hace responsable de los fallos, incompatibilidades y/o daños de tus terminales o dispositivos que, en su caso, se pudiesen derivar de la descarga y/o uso de las Herramientas, especialmente cuando éstos no sean compatibles o adecuados para utilizar nuestros servicios.
                        </p>
                        <h3>12. Uso internacional e idioma</h3>
                        <p>
                        Entiendes y compartes que Mentapp está pensada para ser utilizada por cualquier persona que cumpla las presentes Condiciones, en cualquier país en el que su uso no sea contrario a las leyes o normativas locales vigentes. Asimismo, es posible que Mentapp tenga ciertas restricciones, no funcione adecuadamente o bien todos sus servicios no estén disponibles en todos los países.
                        <br></br>
                        <br></br>
                        El uso de Mentapp por parte de los usuarios dentro de un país concreto se hará bajo la responsabilidad de éstos. Únicamente tú eres responsable de cumplir las leyes de los países desde los que te registres y/o accedas a Mentapp, así como desde los que utilices nuestros servicios.
                        <br></br>
                        <br></br>
                        Si bien las presentes Condiciones se ofrecen traducidas a varios idiomas para garantizar que puedan ser leídas y comprendidas por todos nuestros usuarios, éstos declaran conocer y aceptar que siempre prevalecerá la versión en español en el supuesto de que pudiesen existir contradicciones entre las distintas versiones.
                        </p>
                        <br></br>
                        <p>© Mentapp, 2024. Todos los derechos reservados.</p>
                    </IonContent>
                </div>
            </div>
         );
}
 
export default PrivacyTerms;